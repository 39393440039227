import {
  ComponentWithAs,
  StylesProvider,
  useMultiStyleConfig,
  Grid,
  GridItem,
  GridProps,
  GridItemProps,
  useStyles,
  useTheme,
  css,
  As,
  Heading,
} from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import { HeadingGroup, HeadingGroupProps } from './heading-group'

export interface SectionProps extends GridProps {}
export interface SectionHeadingProps extends GridItemProps, HeadingGroupProps {}
export interface SectionContentProps extends GridItemProps {}

export const Section: ComponentWithAs<As, SectionProps> = forwardRef(
  ({ size, variant, children, ...other }, ref) => {
    const styles = useMultiStyleConfig('Section', { size, variant })

    return (
      <Grid as={'section'} {...styles.section} {...other} ref={ref}>
        <StylesProvider value={styles}>{children}</StylesProvider>
      </Grid>
    )
  }
) as ComponentWithAs<As, SectionProps>

export const SectionHeading: ComponentWithAs<
  As,
  SectionHeadingProps
> = forwardRef((props, ref) => {
  const styles = useStyles()
  return <GridItem as={Heading} {...styles.heading} {...props} ref={ref} />
}) as ComponentWithAs<As, SectionHeadingProps>

export const SectionContent: ComponentWithAs<
  As,
  SectionContentProps
> = forwardRef((props, ref) => {
  const styles = useStyles()
  return <GridItem as={'p'} {...styles.content} {...props} ref={ref} />
}) as ComponentWithAs<As, SectionHeadingProps>
