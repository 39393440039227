import React, { forwardRef } from 'react'
import {
  StylesProvider,
  ComponentWithAs,
  Grid,
  GridProps,
  GridItem,
  GridItemProps,
  useMultiStyleConfig,
  useStyles,
  As,
} from '@chakra-ui/react'

export interface HeadingGroupProps extends GridProps {}
export interface HeadingTitleProps extends GridItemProps {}
export interface HeadingSubtitleProps extends GridItemProps {}

export const HeadingGroup: ComponentWithAs<As, HeadingGroupProps> = forwardRef(
  ({ size, variant, children, ...other }, ref) => {
    const styles = useMultiStyleConfig('HeadingGroup', { size, variant })

    return (
      <Grid as={'div'} {...styles.group} {...other} ref={ref}>
        <StylesProvider value={styles}>{children}</StylesProvider>
      </Grid>
    )
  }
) as ComponentWithAs<As, HeadingGroupProps>

export const HeadingTitle: ComponentWithAs<As, HeadingTitleProps> = forwardRef(
  (props, ref) => {
    const styles = useStyles()

    return <GridItem as={'h2'} {...styles.title} {...props} ref={ref} />
  }
) as ComponentWithAs<As, HeadingTitleProps>

export const HeadingSubtitle: ComponentWithAs<
  As,
  HeadingSubtitleProps
> = forwardRef((props, ref) => {
  const styles = useStyles()

  return <GridItem as={'p'} {...styles.subtitle} {...props} ref={ref} />
}) as ComponentWithAs<As, HeadingSubtitleProps>
