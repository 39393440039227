import React, { ReactNode, useEffect } from 'react'
import { IntersectionOptions, useInView } from 'react-intersection-observer'
import { SlideFade, SlideFadeProps, EASINGS } from '@chakra-ui/react'
import { ComponentWithAs } from '@chakra-ui/system'
import { Transition } from 'framer-motion'

export interface SlideFadeInViewProps
  extends SlideFadeProps,
    IntersectionOptions {
  children: ReactNode
}

type VariantsCustomProps = {
  transition: Transition
  offsetX: number
  offsetY: number
  reverse: boolean
}

const defaultTransitions = {
  enter: {
    duration: 0.5,
    ease: EASINGS.easeOut,
  },
  exit: {
    duration: 0.5,
    ease: EASINGS.easeIn,
  },
} as const

const variants = {
  initial: ({ offsetX, offsetY, transition }: VariantsCustomProps) => ({
    opacity: 0,
    x: offsetX,
    y: offsetY,
    transition,
  }),
  exit: ({ offsetX, offsetY, transition, reverse }: VariantsCustomProps) => ({
    opacity: 0,
    transition,
    ...(reverse && {
      x: offsetX,
      y: offsetY,
    }),
    ...(!reverse && {
      transitionEnd: {
        x: offsetX,
        y: offsetY,
      },
    }),
  }),
  enter: ({ transition }: VariantsCustomProps) => ({
    opacity: 1,
    x: 0,
    y: 0,
    transition,
  }),
} as const

export const SlideFadeInView: ComponentWithAs<'div', SlideFadeInViewProps> = ({
  root,
  rootMargin,
  threshold = 0.1,
  triggerOnce = true,
  skip,
  initialInView,
  trackVisibility,
  delay,
  transition = {
    duration: 1.5,
    ease: EASINGS.easeInOut,
  },
  offsetX = 0,
  offsetY = 50,
  reverse = true,
  ...other
}) => {
  const { ref, inView } = useInView({
    root,
    rootMargin,
    threshold,
    triggerOnce,
    skip,
    initialInView,
    trackVisibility,
  })

  const custom = { transition, offsetX, offsetY, reverse }

  return (
    <>
      <SlideFade
        in={inView}
        custom={custom}
        variants={variants}
        ref={ref}
        {...other}
      />
    </>
  )
}
