import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Hero } from '../components/site-only/hero'
import { Section, SectionHeading } from '../components/section'
import {
  HeadingGroup,
  HeadingSubtitle,
  HeadingTitle,
} from '../components/heading-group'
import {
  Text,
  Container,
  Heading,
  Grid,
  GridItem,
  useToken,
  List,
  ListItem,
} from '@chakra-ui/react'
import { SlideFadeInView } from '../components/slide-fade-in-view'
import { Wrapper } from '../components/site-only/wrapper'
import { ListIcon } from '../components/list-icon'

export default function PrivacyPolicy({
  data: { privacyPolicy },
}: PageProps<GatsbyTypes.PrivacyPolicyQuery>) {
  const section = privacyPolicy?.section

  return (
    <>
      {section && (
        <>
          <Hero
            pb={{
              base: '30vw',
              md: 60,
            }}
          >
            <SlideFadeInView>
              <Section textAlign={'center'}>
                <SectionHeading
                  as={HeadingGroup}
                  variant={'white'}
                  textAlign={{
                    base: 'center',
                    md: 'unset',
                  }}
                >
                  <HeadingTitle>{section.heading?.value}</HeadingTitle>
                  <HeadingSubtitle>
                    {section.subtitle?.element?.value}
                  </HeadingSubtitle>
                </SectionHeading>
              </Section>
            </SlideFadeInView>
          </Hero>
          <Wrapper invert>
            <Container>
              <Grid
                as={'dl'}
                gap={{
                  base: 20,
                  md: 30,
                }}
              >
                {section.sections.map((section, index) => {
                  return (
                    <GridItem key={section.key}>
                      <Heading
                        as={'dt'}
                        size={'xs'}
                        color={'primary.500'}
                        pb={2}
                      >
                        {`${index + 1}. ${section.heading?.value}`}
                      </Heading>
                      <Grid
                        as={'dd'}
                        gap={{
                          base: 10,
                          md: 15,
                        }}
                      >
                        {section.contents.map(content => {
                          if (content.type === 'list') {
                            return (
                              <List
                                key={content.key}
                                spacing={15}
                                variant={'primary'}
                                size={'sm'}
                              >
                                {content.list.map(item => {
                                  return (
                                    <ListItem key={item.key}>
                                      <ListIcon />
                                      {item.contents.map(content => {
                                        if (content.type === 'list') {
                                          return (
                                            <List key={content.key} size={'xs'}>
                                              {content.list.map(item => {
                                                return (
                                                  <ListItem key={item.key}>
                                                    <ListIcon />
                                                    <Text
                                                      dangerouslySetInnerHTML={{
                                                        __html: item.value,
                                                      }}
                                                    />
                                                  </ListItem>
                                                )
                                              })}
                                            </List>
                                          )
                                        } else {
                                          return (
                                            <GridItem
                                              key={content.key}
                                              color={'primary.500'}
                                              textStyle={'md-bold'}
                                              dangerouslySetInnerHTML={{
                                                __html: content.value,
                                              }}
                                            />
                                          )
                                        }
                                      })}
                                    </ListItem>
                                  )
                                })}
                              </List>
                            )
                          } else {
                            return (
                              <GridItem
                                key={content.key}
                                dangerouslySetInnerHTML={{
                                  __html: content.value,
                                }}
                              />
                            )
                          }
                        })}
                      </Grid>
                    </GridItem>
                  )
                })}
              </Grid>
            </Container>
          </Wrapper>
        </>
      )}
    </>
  )
}

export const query = graphql`
  query PrivacyPolicy {
    privacyPolicy: markdownRemark(
      frontmatter: { title: { eq: "Privacy Policy" } }
    ) {
      section: childElement(filter: { type: { eq: "section" } }) {
        tagName
        ...MarkdownHeading
        ...MarkdownSubtitle
        contents: childrenElement(
          filter: {
            type: { in: ["paragraph", "list"] }
            children: { type: { nin: ["strong"] } }
          }
        ) {
          key: id
          type
          tagName
          value
        }
        sections: childrenElement(filter: { type: { eq: "section" } }) {
          key: id
          ...MarkdownHeading
          ...MarkdownSubtitle
          contents: childrenElement(
            filter: {
              type: { in: ["paragraph", "list"] }
              children: { type: { nin: ["strong"] } }
            }
          ) {
            key: id
            type
            tagName
            value
            list: childrenElement(filter: { type: { eq: "listItem" } }) {
              key: id
              tagName
              value
              contents: childrenElement(
                filter: {
                  type: { in: ["paragraph", "list"] }
                  children: { type: { nin: ["strong"] } }
                }
              ) {
                key: id
                type
                tagName
                value
                list: childrenElement(filter: { type: { eq: "listItem" } }) {
                  key: id
                  tagName
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`
