import { Box, BoxProps } from '@chakra-ui/react'
import * as React from 'react'

export interface WrapperProps extends BoxProps {
  invert?: boolean
}

export interface WrapperFluidProps extends BoxProps {
  mobileOnly?: boolean
}

export function Wrapper({ invert, ...other }: WrapperProps) {
  const invertClass = invert
    ? {
        className: 'header-color-invert',
      }
    : {}

  return (
    <Box
      position={'relative'}
      px={{
        base: 10,
        sm: 15,
        md: 20,
      }}
      py={{
        base: 25,
        md: 50,
      }}
      {...invertClass}
      {...other}
    />
  )
}

export function WrapperFluid({ mobileOnly, ...other }: WrapperFluidProps) {
  const display = mobileOnly
    ? {
        display: {
          md: 'contents',
        },
      }
    : {}
  return (
    <Box
      position={'relative'}
      mx={'calc(((100vw - 100%) / 2) * -1)'}
      {...display}
      {...other}
    />
  )
}
